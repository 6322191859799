<script>
  import Layout from '@layouts/contract-print';
  import ContentLoading from '@components/content-loading';
  import { PlanProductTypesEnum } from '@enums/plan';
  import {
    formatPrice,
    formatNumber,
    formatCpf,
    formatVat,
    formatPhone,
  } from '@utils/numbers';
  import { formatFuel } from '@utils/car';
  import { i18next } from '@translate/i18n';

  import DRIVER_BOOKING_PRINT from '@graphql/booking/queries/print.gql';
  import { transactionKindEnum } from '@utils/transaction';
  import { isBrazil } from '@utils/helper-region'

  export default {
    name: 'DriverBookings',
    page: {
      title: i18next.t('contract.texts.pageTitle'),
    },
    components: {
      Layout,
      ContentLoading,
    },
    data: () => {
      return {
        page_title: i18next.t('contract.texts.pageLoading'),
        attendentKovi: '',
        transactionKindEnum,
        product_types: PlanProductTypesEnum,
      };
    },
    apollo: {
      booking: {
        query: DRIVER_BOOKING_PRINT,
        variables() {
          return {
            id: this.$route.params.booking,
          };
        },
      },
    },
    mounted() {
      this.attendentKovi = this.$store.getters['user/attributes'].email.replace('@kovi.com.', ' @ ').replace('@kovi.', ' @ ');
    },
    computed: {
      countryIsBrazil () {
        return isBrazil()
      }
    },
    methods: {
      setFuelFormat(fuel, spacer) {
        return formatFuel(fuel, spacer);
      },
      setPriceFormat(_value) {
        return formatPrice(i18next.t('contract.words.priceFormat'), _value);
      },
      setDiscountFormat(_value) {
        return formatNumber(_value) + '%';
      },
      setNumberFormat(_value) {
        return formatNumber(_value);
      },
      setCpfFormat(_value) {
        return formatCpf(_value);
      },
      setVatFormat(_value) {
        return formatVat(_value);
      },
      setPhoneFormat(_value) {
        return formatPhone(_value);
      },
      setCycle(total) {
        if (total === 7) {
          return i18next.t('contract.words.weekly');
        } else if (total === 15) {
          return this.$$i18n.t('contract.words.biweekly');
        } else if (total === 30) {
          return i18next.t('contract.words.mounthly');
        } else {
          return `${total} ${i18next.t('contract.words.days')}`;
        }
      },
      setPaymentType(_type) {
        return _type === 'boleto'
          ? i18next.t('contract.texts.boleto')
          : _type === 'credit_card'
          ? i18next.t('contract.texts.creditCard')
          : i18next.t('contract.texts.debitCard');
      },
      setKindAmount(kind) {
        const value = this.getKindAmount(kind)
        return formatPrice(i18next.t('contract.words.priceFormat'), value)
      },
      getKindAmount(kind) {
        return this.booking.plan.kind_configurations[kind].amount
      },
      getPrePaymentKind() {
        return this.isRTO(this.booking.plan) ? transactionKindEnum.PRE_PAYMENT_RTO : transactionKindEnum.PRE_PAYMENT;
      },
      getFinishedDate(started_at, month) {
        return this.$moment(started_at).add(month, 'M');
      },
      isRTO(plan) {
        return plan.product === 'RENT_TO_OWN';
      },
    },
  };
</script>

<template>
  <Layout>
    <div v-if="!$apollo.queries.booking.loading">
      <table class="header">
        <thead>
          <tr>
            <td class="logo">
              <img :src="require('@public/img/brand/logo-print.svg')" width="170" height="41">
            </td>
            <td class="about">
              <h1>
                {{ $i18n.t('contract.titles.contractOfAdhesion') }}
              </h1>
              <h2>
                {{ $i18n.t('contract.titles.koviCnpj') }}
              </h2>
            </td>
            <td class="contract">
              <h2>
                <strong>{{ $i18n.t('contract.words.contract') }}:</strong> {{ $i18n.t('contract.texts.contractCode', { code: $moment(booking.created_at).format('X') }) }}
              </h2>
            </td>
          </tr>
        </thead>
      </table>
      <table class="info">
        <thead>
          <tr>
            <th class="driver">
              {{ $i18n.t('contract.words.client') }}
            </th>
            <th class="car">
              {{ $i18n.t('contract.words.vehicle') }}
            </th>
            <th class="booking">
              {{ $i18n.t('contract.words.location') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="driver">
              <p style="width:100%">
                <strong>{{ $i18n.t('contract.words.name') }}:</strong>
                {{ booking.driver.name }}
              </p>
              <p>
                <strong>{{ $i18n.t('contract.words.license_number') }}:</strong>
                {{ setNumberFormat(booking.driver.license_number) }}
              </p>
              <p>
                <strong>{{ $i18n.t('contract.words.document') }}:</strong>
                {{ setCpfFormat(booking.driver.cpf) }}
              </p>
              <p>
                <strong>{{ $i18n.t('contract.words.phone') }}:</strong>
                {{ setPhoneFormat(booking.driver.phone_number) }}
              </p>
              <p style="width:100%">
                <strong>{{ $i18n.t('contract.texts.contractedPlan') }}:</strong>
                {{ booking.plan.name }}
              </p>
            </td>
            <td class="car">
              <p>
                <strong>{{ $i18n.t('contract.words.plate') }}:</strong>
                {{ booking.car.license_plate }}
              </p>
              <p style="width:100%">
                <strong>{{ $i18n.t('contract.words.model') }}:</strong>
                {{ booking.car.brand.name }} {{ booking.car.version }}
              </p>
              <p>
                <strong>{{ $i18n.t('contract.words.renavam') }}:</strong>
                {{ setNumberFormat(booking.car.license_number) }}
              </p>
              <p>
                <strong>{{ $i18n.t('contract.words.fuel') }}:</strong>
                {{ setFuelFormat(booking.car.fuel, '/') }}
              </p>
              <p>
                <strong>{{ $i18n.t('contract.texts.currentKm') }}:</strong>&nbsp;
                <span v-if="booking.odometer > 0">
                  {{
                    setNumberFormat(booking.odometer)
                  }}
                </span>
                <span
                  v-else
                >&nbsp;[&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;]&nbsp;</span>
                {{ $i18n.t('contract.words.km') }}
              </p>
            </td>
            <td class="booking">
              <p
                v-html="$i18n.t('contract.texts.startDate', { date: $moment(booking.started_at).format('DD/MM/YYYY') })"
              />
              <p>
                <span v-html="$i18n.t('contract.texts.endDate')" />
                <span v-if="booking.finished_at">
                  {{
                    booking.finished_at | moment('DD/MM/YYYY')
                  }}
                </span>
                <span v-else>
                  {{
                    this.getFinishedDate(booking.started_at, this.countryIsBrazil ? 60 : 18) | moment('DD/MM/YYYY')
                  }}
                </span>
              </p>
              <p v-if="booking.plan.product_type == product_types.KOVI_PROPRIO_2">
                {{ $i18n.t('contract.texts.purchaseOptionPeriod', {purchase_option_period: setNumberFormat(booking.plan.purchase_option_period)})}}
              </p>
              <p>{{ $i18n.t('contract.texts.phraseLocationWillBeExtended') }}</p>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="legal">
        <thead>
          <tr>
            <th class="general">
              {{ $i18n.t('contract.texts.generalProvision') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="$i18n.i18next.t('contract.texts.phraseClauseOne')">
            <td>
              <strong
                v-if="$i18n.i18next.exists('contract.texts.clauseOne')"
              >{{ $i18n.t('contract.texts.clauseOne') }}</strong>
              {{ $i18n.t('contract.texts.phraseClauseOne') }}
            </td>
          </tr>
          <tr v-if="$i18n.i18next.t('contract.texts.phraseClauseTwo')">
            <td>
              <strong
                v-if="$i18n.i18next.exists('contract.texts.clauseTwo')"
              >{{ $i18n.t('contract.texts.clauseTwo') }}</strong>
              {{ $i18n.t('contract.texts.phraseClauseTwo') }}
            </td>
          </tr>
          <tr v-if="$i18n.i18next.t('contract.texts.phraseClauseThree')">
            <td>
              <strong
                v-if="$i18n.i18next.exists('contract.texts.clauseThree')"
              >{{ $i18n.t('contract.texts.clauseThree') }}</strong>
              {{
                $i18n.t(
                  'contract.texts.phraseClauseThree', {
                    license_plate: booking.car.license_plate,
                    company_name: booking.car.order.contract.company_name,
                    company_vat: setVatFormat(booking.car.order.contract.company_vat),
                  }
                )
              }}
            </td>
          </tr>
          <tr v-if="$i18n.i18next.t('contract.texts.phraseClauseFour') && !countryIsBrazil">
            <td>
              <strong
                v-if="$i18n.i18next.exists('contract.texts.clauseFour')"
              >{{ $i18n.t('contract.texts.clauseFour') }}</strong>
              {{ $i18n.t('contract.texts.phraseClauseFour') }}
            </td>
          </tr>
          <tr v-if="!countryIsBrazil">
            <td v-html="$i18n.t('contract.texts.otherClauses')" />
          </tr>
        </tbody>
      </table>
      <table class="comercial-agreements">
        <thead>
          <tr>
            <th colspan="2" class="comercial">
              {{ $i18n.t('contract.texts.commercialConditions') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td class="description">
              <h1>{{ $i18n.t('contract.words.rental') }}</h1>
              <table>
                <tr>
                  <th class="label">
                    &nbsp;
                  </th>
                  <th>{{ $i18n.t('contract.words.valueUnit') }}</th>
                  <th>{{ $i18n.t('contract.words.descPercent') }}</th>
                  <th>{{ $i18n.t('contract.words.descCurrency') }}</th>
                  <th>{{ $i18n.t('contract.words.quantity') }}</th>
                  <th>{{ $i18n.t('contract.words.totalValue') }}</th>
                  <th>{{ $i18n.t('contract.words.payment') }}</th>
                </tr>
                <tr>
                  <td>{{ $i18n.t('contract.words.bond') }}</td>
                  <td>{{ setKindAmount(getPrePaymentKind()) }}</td>
                  <td>{{ setDiscountFormat(booking.pre_transaction_discount) }}</td>
                  <td>
                    {{
                      setPriceFormat(
                       getKindAmount(getPrePaymentKind()) * booking.pre_transaction_discount
                      )
                    }}
                  </td>
                  <td>1</td>
                  <td>
                    {{
                      setPriceFormat(
                        getKindAmount(getPrePaymentKind()) -
                          getKindAmount(getPrePaymentKind()) * booking.pre_transaction_discount
                      )
                    }}
                  </td>
                  <td>{{ setPaymentType(booking.pre_transaction_type) }}</td>
                </tr>
                <tr>
                  <td>{{$i18n.t('contract.words.rental') }}</td>
                  <td>{{ setKindAmount(transactionKindEnum.RECURRENCY) }}</td>
                  <td>{{ setDiscountFormat(booking.transaction_discount) }}</td>
                  <td>{{ setPriceFormat(getKindAmount(transactionKindEnum.RECURRENCY) * booking.transaction_discount) }}</td>
                  <td>1</td>
                  <td>
                    {{
                      setPriceFormat(
                        getKindAmount(transactionKindEnum.RECURRENCY) -
                          getKindAmount(transactionKindEnum.RECURRENCY) * booking.transaction_discount
                      )
                    }}
                  </td>
                  <td>{{ setPaymentType(booking.transaction_type) }}</td>
                </tr>
                <tr>
                  <td>{{$i18n.t('contract.words.subscriptionTerminationFine') }}</td>
                  <td> - </td>
                  <td> - </td>
                  <td> - </td>
                  <td> - </td>
                  <td>
                    {{ setKindAmount(transactionKindEnum.SUBSCRIPTION_TERMINATION) }}
                  </td>
                  <td> - </td>
                </tr>
                <!-- <tr>
                  <td>{{ item.name }}</td>
                  <td>{{ setPriceFormat(item.amount) }}</td>
                  <td>{{ setDiscountFormat(item.discount) }}</td>
                  <td>{{ setPriceFormat(item.amount * item.discount) }}</td>
                  <td>{{ setNumberFormat(item.total) }}</td>
                  <td>{{ setPriceFormat(item.amount - (item.amount * item.discount)) }}</td>
                  <td>{{ setPaymentType(item.payment_method_type) }}</td>
                </tr>-->
              </table>
            </td>
            <td class="protection">
              <h1>{{ $i18n.t('contract.texts.protectionPackage') }}</h1>
              <table v-if="countryIsBrazil" class="protection-brazil">
                <tr>
                  <td style="width: 70%; text-align: left">{{ $i18n.t('contract.texts.coParticipationAccident') }}</td>
                  <td><span class="protection-brazil-price">{{ $i18n.t('contract.texts.coParticipationAccidentPrice') }}</span></td>
                </tr>
                <tr>
                  <td style="width: 70%; text-align: left">{{ $i18n.t('contract.texts.coParticipationTheft') }}</td>
                  <td><span class="protection-brazil-price">{{ $i18n.t('contract.texts.coParticipationTheftPrice') }}</span></td>
                </tr>
                <tr>
                  <td style="width: 70%; text-align: left">{{ $i18n.t('contract.texts.coParticipationTheftInOtherCases') }}</td>
                  <td><span class="protection-brazil-price">{{ $i18n.t('contract.texts.coParticipationTheftInOtherCasesPrice') }}</span></td>
                </tr>
                <tr>
                  <td colspan="2" style="text-align: left">
                    <span class="general">{{ $i18n.t('contract.texts.phraseOneTerm') }}</span><br><br>
                    <span class="general">{{ $i18n.t('contract.texts.phraseTwoTerm') }}</span>
                  </td>
                  <!-- <li>{{ $i18n.t('contract.texts.phraseThreeTerm') }}</li> -->
                </tr>
              </table>

              <table v-else>
                <tr>
                  <th>{{ $i18n.t('contract.texts.coParticipationAccident') }}</th>
                  <td>{{ $i18n.t('contract.texts.coParticipationAccidentPrice') }}</td>
                </tr>
                <tr>
                  <th>{{ $i18n.t('contract.texts.coParticipationTheft') }}</th>
                  <td>{{ $i18n.t('contract.texts.coParticipationTheftPrice') }}</td>
                </tr>
                <tr>
                  <th>{{ $i18n.t('contract.texts.coParticipationTheftInOtherCases') }}</th>
                  <td>{{ $i18n.t('contract.texts.coParticipationTheftInOtherCasesPrice') }}</td>
                </tr>
                <tr>
                  <th>
                    {{ $i18n.t('contract.texts.phraseCoverageLimit') }}
                    <br>
                    {{ $i18n.t('contract.texts.phraseCoverageLimitWarning') }}
                  </th>
                  <td>{{ $i18n.t('contract.texts.coverageLimitPrice') }}</td>
                </tr>
              </table>
            </td>
          </tr>
        </tbody>
      </table>
      <table class="legal">
        <thead>
          <tr>
            <th class="general">
              {{ $i18n.t('contract.words.requests') }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              class="garage"
              v-html="`${$i18n.t('contract.texts.requestPhraseOne')} &nbsp;&nbsp; [&nbsp;&nbsp;&nbsp;]`"
            />
          </tr>
          <tr>
            <td
              class="garage"
              v-html="`${$i18n.t('contract.texts.requestPhraseTwo')} &nbsp;&nbsp; [&nbsp;&nbsp;&nbsp;]`"
            />
          </tr>
          <tr>
            <td
              class="garage"
              v-html="`${$i18n.t('contract.texts.requestPhraseThree')} &nbsp;&nbsp; [&nbsp;&nbsp;&nbsp;]`"
            />
          </tr>
          <tr v-if="$i18n.i18next.exists('contract.texts.requestPhraseFour')">
            <td
              class="garage"
              v-html="`${$i18n.t('contract.texts.requestPhraseFour')} &nbsp;&nbsp; [&nbsp;&nbsp;&nbsp;]`"
            />
          </tr>
          <tr v-if="$i18n.i18next.exists('contract.texts.requestPhraseFive')">
            <td
              class="garage"
              v-html="`${$i18n.t('contract.texts.requestPhraseFive')} &nbsp;&nbsp; [&nbsp;&nbsp;&nbsp;]`"
            />
          </tr>
          <!-- <tr>
            <td class="garage">
              {{ $i18n.t('contract.texts.phraseNoticePossibleCancellation') }} &nbsp;&nbsp; [&nbsp;&nbsp;&nbsp;]
            </td>
          </tr> -->
        </tbody>
      </table>

      <div class="signature">
        <table>
          <tr>
            <td class="area">
              <strong>{{ $i18n.t('contract.words.signature') }}</strong>
              <br>
              <span>{{ booking.driver.name }}</span>
              <br>
              {{ $i18n.t('contract.words.document') }} {{ booking.driver.cpf }}
            </td>
            <td class="spacer">
              &nbsp;&nbsp;&nbsp;
            </td>
            <td class="area">
              <strong>{{ $i18n.t('contract.texts.attendentKovi') }}</strong>
              <br>
              {{ attendentKovi }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div v-else class="animated fadeIn">
      <content-loading />
    </div>
  </Layout>
</template>

<style lang="scss" scoped>
  @media print {
    @page {
      size: A4 landscape;
      margin: 0;
    }
  }
  body.print,
  html {
    background-color: #fff !important;
    height: 100%;
  }
  .contract-print {
    padding: 0;
    padding-top: 30px;
    background-color: #fff !important;
    height: 100%;
  }
  table {
    width: 100%;
    border: none;
  }
  .header {
    margin-bottom: 10px;

    td {
      padding-bottom: 20px;
      &.logo {
        width: 200px;
      }
      &.about {
        vertical-align: bottom;
        h1 {
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 5px;
        }
        h2 {
          font-size: 14px;
          font-weight: 100;
          margin: 0;
        }
      }
      &.contract {
        vertical-align: bottom;
        text-align: right;
        h1 {
          font-size: 14px;
          font-weight: 600;
          margin-bottom: 3px;
        }
        h2 {
          font-size: 14px;
          font-weight: 100;
          margin: 0;
        }
      }
    }
  }
  .comercial-agreements,
  .info,
  .legal {
    margin-top: 10px;
    th {
      &.booking,
      &.car,
      &.comercial,
      &.driver,
      &.general {
        text-transform: uppercase;
        font-size: 1.1em;
        font-weight: 700;
        margin: 0;
        padding: 0;
      }

      &.car,
      &.driver {
        width: 35%;
      }

      &.booking {
        width: 30%;
      }
    }
    td {
      padding: 3px;
      vertical-align: top;
      &.booking,
      &.car,
      &.driver {
        border: 1px solid #c0c0c0;
        font-size: 0.8em;
        p {
          margin: 0;
          line-height: 1.8em;
          width: 50%;
          float: left;
        }
      }

      &.booking {
        p {
          width: 100%;
        }
      }

      &.booking-disclaimer {
        padding: 6px 4px;
        font-size: 0.7em;
        p {
          margin: 0;
          line-height: 1.6em;
        }
      }
      &.place-disclaimer {
        padding: 6px 4px;
        font-size: 0.8em;
        p {
          margin: 0;
          line-height: 1.6em;
        }
      }
    }
  }
  .comercial-agreements {
    td {
      h1 {
        font-size: 0.95em;
        font-weight: 600;
        margin-bottom: 3px;
      }
      &.description,
      &.protection {
        padding: 6px;
        border: 1px solid #c0c0c0;
      }
      &.description {
        width: 60%;
        table {
          padding: 0;
          td,
          th {
            font-size: 0.7em;
            padding: 5px 0;
          }
          th {
            border-bottom: 1px solid #c0c0c0;
            &.label {
              width: 20%;
            }
            &.total-description {
              border: none;
            }
          }
          td {
            padding: 10px 0;
            border-bottom: 1px dashed #c0c0c0;

            &.total-amount {
              border: none;
              font-weight: 900;
            }
          }
          tr:last-child td {
            border: none;
          }
        }
      }
      &.protection {
        width: 40%;
        .protection-brazil {
          p {
            margin: 0;
            font-size: 0.7em;
            font-weight: normal;

            &:not(:last-child) {
              margin-top: 5px;
            }

            .protection-brazil-price {
              font-weight: bold;
            }
          }

          .protection-brazil-list {
            list-style-type: none;
            padding: 0;
            padding-top: 16px;
            font-size: 0.7em;
            font-weight: bold;
            margin: 0;
            padding-bottom: 5px;

            li {
              &:not(:first-child) {
                margin-top: 2px;
              }
            }
          }
        }

        table {
          padding: 0;
          td,
          th {
            font-size: 0.6em;
            padding: 5px 0;
            border-bottom: 1px dashed #c0c0c0;
          }
          th {
            width: 80%;
            font-weight: normal;
          }
          td {
            font-weight: bold;
            text-align: right;
            width: 20%;
          }
          tr:last-child td,
          tr:last-child th {
            border: none;
          }
        }
      }
    }
  }
  .legal {
    tbody {
      border: 1px solid #c0c0c0;
      td {
        padding: 6px;
        font-size: 0.7em;
      }
    }
  }
  .garage {
    padding: 2px !important;
  }
  .signature {
    width: 100%;
    text-align: center;
    table {
      margin: 60px auto 0;
      width: 60%;
      td {
        &.spacer {
          width: 100px;
        }
        &.area {
          vertical-align: top;
          width: 49%;
          text-align: center;
          border-top: 2px solid #333;
          padding-top: 5px;
          font-size: 0.6em;
          line-height: 1.4em;
          strong {
            font-size: 1.4em;
          }
        }
      }
    }
  }
</style>
