<script>
export default {
  name: 'ContractContainer',
}
</script>
<template>
  <div class="contract-print">
    <div class="contract-print-body">
      <main class="main">
        <div class="container-fluid">
          <slot />
        </div>
      </main>
    </div>
  </div>
</template>
