var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "contract-print" }, [
    _c("div", { staticClass: "contract-print-body" }, [
      _c("main", { staticClass: "main" }, [
        _c("div", { staticClass: "container-fluid" }, [_vm._t("default")], 2),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }